<template>
    <div class="w-p-100 h-p-100 bg-f3">
      <div class="h-54 w-p-100 flex alignCenter justifyBetween">
        <van-search
          class="w-p-100"
          v-model="query.detail"
          show-action
          background="#F3F3F3"
          @search="onSearch(1)"
          placeholder="关键字查询"
        >
          <template #action>
            <div class="flex alignCenter">
              <van-button size="mini" @click="onSearch(1)" type="primary">查询</van-button>
              <van-button size="mini" @click="showSidebar" type="primary">更多条件</van-button>
              <van-button size="mini" @click="addOrUpdate(0)" v-if="permission.insertflag == 1" type="primary">新增</van-button>
            </div>
          </template>
        </van-search>
      </div>
      <div class="h-calc-54">
        <van-pull-refresh
          v-model="loading"
          @refresh="refresh"
          @load="onLoad"
          :finished="finished"
          finished-text="没有更多了"
          class="h-calc-45 scroll-y"
        >
          <div v-if="list.length > 0" class="w-p-100 h-p-100">
            <div
              class="w-calc-48 Fbg lP-24 rP-24 bM-4 tP-5 bP-5 relative"
              v-for="item in list"
              :key="item"
            >
              <!-- <div class="h-45 flex alignCenter justifyBetween">
                <div class="size-20">{{ dayjs(item.risk_judgement_date).format('YYYY-MM-DD') }}</div>
                <div class="size-18 color-80">{{ item.status }}</div>
              </div> -->
              <div >
                <van-field
                v-model="item.type"
                name="type"
                label="风险研判类型"
                />
                <van-field
                v-model="item.sys_class_id"
                name="sys_class_id"
                label="风险研判班组"
                />
                <van-field
                v-model="item.detail"
                name="detail"
                label="风险研判内容"
                />
                <van-field
                v-model="item.cems_choice"
                name="cems_choice"
                label="默认选项"
                />
                <van-field
                v-model="item.create_by"
                name="create_by"
                label="提交人"
                />
                <van-field
                v-model="item.isenable"
                name="isenable"
                label="是否启用"
                />
              </div>
              <div
                class="h-35 flex alignCenter justifyBetween size-14 color-80"
              >
                <div class="flex r-24 b-12 alignCenter justifyEnd">
                  <div
                    class="size-14 flex alignCenter justifyCenter bg-0F60A7 color-fff padding-5 lM-5 radius-4"
                    @click="addOrUpdate(item.id)"
                  >
                    详情
                  </div>
                  <div
                    class="size-14 flex alignCenter justifyCenter bg-ff0000 color-fff padding-5 lM-5 radius-4"
                    @click="del(item.id)"
                  >
                    删除
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <van-empty description="暂无数据"></van-empty>
          </div>
        </van-pull-refresh>
      </div>
    </div>
    <van-popup v-model:show="sideVisible" position="left" :overlay="false" @close="closeSidebar">
        <div class="margin-0 bg-transparent lP-24 rP-24 tP-40">
          风险研判类型
            <van-field
            is-link
            v-model="state.type"
            name="type"
            @click="picker.type = true"
            />
            风险研判内容
            <van-field
            v-model="query.detail"
            name="detail"
            placeholder="风险研判内容"
            />
           
            <!-- 下拉内容 -->
            <van-popup v-model:show="picker.type" position="bottom">
            <van-picker
                :columns="options.RISK_JUDGEMENT_TYPE"
                @confirm="
                data => {
                    confirm(data, 'type');
                }
                "
                :columns-field-names="{ text: 'PARAMETER_NAME', value: 'PARAMETER_VALUE' }"
                @cancel="picker.type = false"
            />
            </van-popup>
            提交人
            <van-field
            v-model="query.create_by"
            name="create_by"
            placeholder="提交人"
            />
        </div>
        <div class="flex justifyCenter">
            <van-button size="mini" @click="onSearch(1)" type="primary">查询</van-button>
            <van-button size="mini" @click="closeSidebar()" type="info">关闭</van-button>
      </div>

            
    </van-popup>
    <van-calendar
      v-model:show="showCalendar"
      @confirm="calendarOnConfirm"
      :min-date="new Date('2000-01-01')"
    />
    <van-calendar
      v-model:show="showCalendar2"
      @confirm="calendarOnConfirm2"
      :min-date="new Date('2000-01-01')"
    />
</template>
<script setup>
/* eslint-disable */
import { onMounted, reactive ,ref} from 'vue';
import http from '../../../api/http';
import { useRouter,useRoute } from 'vue-router';
import dayjs from 'dayjs';
import {Toast,Dialog} from 'vant'
const router = useRouter();
const route = useRoute();

import global from '../../../api/global';
const permission = reactive(global.getPagePermission(route.name));

const showCalendar = ref(false);
const showCalendar2 = ref(false);
const sideVisible = ref(false);
const showSidebar = () =>{
    sideVisible.value = true;
}
const closeSidebar = () =>{
    sideVisible.value = false;
}
const list = ref([]);
const query = reactive({
    method:'query',
    queryId:3003,
    type:'',
    detail:'',
    create_by:'',
    sort:'',
    page:1,
    pageSize:20
});
const state = reactive({
    method:'query',
    queryId:3003,
    type:'',
    detail:'',
    create_by:'',
    sort:'',
    page:1,
    pageSize:20
});
const picker = reactive({
    type:false,
    RISK_JUDGEMENT_CHOICE:false
});
const options = reactive({
    RISK_JUDGEMENT_TYPE:[],
    RISK_JUDGEMENT_CHOICE:[]
}); 
const confirm = (data,type) =>{
    query[type] = data.PARAMETER_VALUE;
    state[type] = data.PARAMETER_NAME;
    picker[type] = false;        
}
const getSelectList = () =>{
    http.get({
        method:'query',
        queryId:3008,
        parameter_types:"RISK_JUDGEMENT_CHOICE,RISK_JUDGEMENT_TYPE"
    },true)
    .then(res=>{
        options.RISK_JUDGEMENT_TYPE = res.resultData.RISK_JUDGEMENT_TYPE;
        options.RISK_JUDGEMENT_CHOICE = res.resultData.RISK_JUDGEMENT_CHOICE;

        if(route.query.type){
          let item = options.RISK_JUDGEMENT_TYPE.find(e=>e.PARAMETER_VALUE==route.query.type)
          state.type = item.PARAMETER_NAME
        }else{
          state.type = ''
        }
        
    })
}

// 新增 编辑
const addOrUpdate = (id) =>{
    // router.push('/RstConfigDetail/'+id)
    router.push({
                path:'/RstConfigDetail/'+id, 
                query:{
                    type:query.type,
                }
            })
}
// 删除
const del = (ids) =>{
    Dialog.confirm({
        message:'此操作将删除所选数据,请确认？',
    })
    .then(()=>{
        http.post({
            method:'delete',
            t:'RISK_JUDGEMENT_SETTING',
            ids:ids
        },true,false,false,'delete')
        .then(()=>{
            onSearch(1);
        })
    })
    .catch(()=>{})
} 
const finished = ref(false)
const loading = ref(false);
const lodingmore = ref(false)
const onSearch = (page) =>{
    query.page = page;
    loading.value = false;
    Toast.loading({message: '加载中...',duration:0});
    http.get(query)
    .then(res=>{
        console.log(res)
        list.value = res.map;
        
    })
    .catch(()=>{
        Toast.clear();
    })
    // finished.value = true;
}
// 下拉刷新
const refresh = () =>{
    loading.value = true;
    onSearch(1)
}
// 
const onLoad = ()=>{

}
const calendarOnConfirm = (date) =>{
    query.startdate =  dayjs(date).format('YYYY-MM-DD');//`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    showCalendar.value = false;
    // onSearch(1)
}
const calendarOnConfirm2 = (date) =>{
    query.enddate =  dayjs(date).format('YYYY-MM-DD');//`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    showCalendar2.value = false;
    // onSearch(1)
}

onMounted(()=>{
    getSelectList()
    query.type = route.query.type?route.query.type:'';
    onSearch(1);
})
</script>